// Generated by Framer (2f783ed)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["A5K5QSqiM", "fz2DWlB1q"];

const serializationHash = "framer-AfRnV"

const variantClassNames = {A5K5QSqiM: "framer-v-1eeoio2", fz2DWlB1q: "framer-v-vc02bc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Black: "A5K5QSqiM", Grey: "fz2DWlB1q"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "A5K5QSqiM"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "A5K5QSqiM", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1eeoio2", className, classNames)} data-framer-name={"Black"} layoutDependency={layoutDependency} layoutId={"A5K5QSqiM"} ref={refBinding} style={{...style}} {...addPropertyOverrides({fz2DWlB1q: {"data-framer-name": "Grey"}}, baseVariant, gestureVariant)}><SVG className={"framer-jvymb7"} data-framer-name={"Ic-arrow-down"} layout={"position"} layoutDependency={layoutDependency} layoutId={"GaJUXUHtF"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 20\"><path d=\"M 16 4 L 16 16 L 4 16 M 4 4 L 16 16\" fill=\"transparent\" stroke-width=\"1.5\" stroke=\"rgb(20,20,31)\" stroke-miterlimit=\"10\" stroke-dasharray=\"\"></path></svg>"} svgContentId={10541954891} withExternalLayout {...addPropertyOverrides({fz2DWlB1q: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 20\"><path d=\"M 16 4 L 16 16 L 4 16 M 4 4 L 16 16\" fill=\"transparent\" stroke-width=\"1.5\" stroke=\"var(--token-9e64e3d7-e30b-4163-bbe3-554088df910d, rgb(132, 132, 132)) /* {&quot;name&quot;:&quot;grey-400&quot;} */\" stroke-miterlimit=\"10\" stroke-dasharray=\"\"></path></svg>", svgContentId: 11670947235}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AfRnV.framer-znxbvd, .framer-AfRnV .framer-znxbvd { display: block; }", ".framer-AfRnV.framer-1eeoio2 { height: 20px; overflow: visible; position: relative; width: 20px; }", ".framer-AfRnV .framer-jvymb7 { flex: none; height: 20px; left: 0px; position: absolute; top: 0px; width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"fz2DWlB1q":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLFzxTHpJg: React.ComponentType<Props> = withCSS(Component, css, "framer-AfRnV") as typeof Component;
export default FramerLFzxTHpJg;

FramerLFzxTHpJg.displayName = "ic-arrow-down";

FramerLFzxTHpJg.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerLFzxTHpJg, {variant: {options: ["A5K5QSqiM", "fz2DWlB1q"], optionTitles: ["Black", "Grey"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerLFzxTHpJg, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})